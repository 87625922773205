/* eslint-disable prefer-template, no-console */
import NextImage from 'next/legacy/image'
import { useCallback } from 'react'
import compact from 'lodash/compact'

type ImageLoaderProps = {
  src: string
  width: number
  quality?: number
  root?: string
}

function normalizeSrc(src: string): string {
  return src[0] === '/' ? src.slice(1) : src
}

const CLOUDINARY_HOST = 'https://bestfoodtrucks.mo.cloudinary.net'

type Props = Parameters<typeof NextImage>[0] & {
  // https://cloudinary.com/documentation/media_optimizer_transformations#automatic_quality
  quality?:
    | 'auto'
    | 'auto:best'
    | 'auth:good'
    | 'auto:eco'
    | 'auto:low'
    | number
  gravity?:
    | 'auto'
    | 'center'
    | 'north_east'
    | 'north'
    | 'north_west'
    | 'west'
    | 'south_west'
    | 'south'
    | 'south_east'
    | 'east'
  crop?:
    | 'crop'
    | 'fill'
    | 'fill_pad'
    | 'fit'
    | 'lfill'
    | 'limit'
    | 'lpad'
    | 'mfit'
    | 'mpad'
    | 'pad'
    | 'scale'
    | 'thumb'
}

// https://cloudinary.com/documentation/transformation_reference
const Image = ({
  quality = 75,
  gravity = 'center',
  crop = 'limit',
  width: initialWidth,
  height: initialHeight,
  ...props
}: Props): ReturnType<typeof NextImage> => {
  const aspectRatio: number | null =
    typeof initialWidth === 'number' && typeof initialHeight === 'number'
      ? initialWidth / initialHeight
      : null
  const cloudinaryLoader = useCallback(
    ({ src, width }: ImageLoaderProps): string => {
      const height =
        gravity === 'auto' && aspectRatio
          ? Math.round(width / aspectRatio)
          : null
      const params = compact([
        'f_auto',
        'c_' + crop,
        'g_' + gravity,
        'w_' + width,
        height ? 'h_' + height : '',
        'q_' + (quality || 'auto')
      ])
      if (
        gravity === 'auto' &&
        !['fill', 'lfill', 'fill_pad', 'thumb', 'crop'].includes(crop)
      ) {
        console.error(
          'Automatic cropping is supported for the fill, lfill, fill_pad, thumb and crop modes.'
        )
      }
      const paramsString = `tx=${params.join(',')}&resource_type=image`
      let normalizedSrc = normalizeSrc(src)
      if (process.env.NODE_ENV === 'development') {
        normalizedSrc = normalizedSrc.replace(
          /http:\/\/customers\.bft\.com(:?)\d*/,
          'https://www.bestfoodtrucks.com'
        )
      }
      const parsedUrl = new URL(src)
      const joiner = parsedUrl.search && parsedUrl.search.length ? '&' : '?'
      return `${CLOUDINARY_HOST}/${normalizedSrc}${joiner}${paramsString}`
    },
    [crop, quality, gravity, aspectRatio] // height
  )

  /* eslint-disable react/destructuring-assignment, @typescript-eslint/no-explicit-any */
  let imageSrc: string | null = null
  if (!props.src) {
    imageSrc = null
  } else if (typeof props.src === 'string') {
    imageSrc = props.src
  } else if (typeof (props.src as any).default !== 'undefined') {
    imageSrc = (props.src as any).default.src
  } else if (typeof (props.src as any).src !== 'undefined') {
    imageSrc = (props.src as any).src
  }
  /* eslint-enable react/destructuring-assignment, @typescript-eslint/no-explicit-any */
  const skipCloudinary =
    imageSrc?.includes('_next/static') ||
    (process.env.NODE_ENV === 'development' &&
      (imageSrc?.includes('/assets/') ||
        imageSrc?.includes('/rails/active_storage/')))
  return (
    <NextImage
      {...props}
      height={initialHeight}
      loader={skipCloudinary ? undefined : cloudinaryLoader}
      width={initialWidth}
    />
  )
}

export default Image

import ButtonLink from 'components/ui/ButtonLink'
import React from 'react'

const faq = [
  {
    title: 'How to book food trucks for offices?',
    categoryCode: 'catering',
    slug: 'how-do-i-book-food-trucks-for-my-office',
    hideForBoeing: true,
    html: (
      <div>
        <p>
          We get it. You’re sick of going to the same three restaurants over and
          over. Delivery food comes lukewarm at best, meaning say goodbye to
          crispy french fries, and get used to using words like “soggy” and
          “stale”. Catering trays where you have little choice, and 25% of that
          food is likely going into the trash.
        </p>
        <p>
          That’s where food trucks come in. Food trucks that will come to your
          work are the best possible solution to bring a professional chef and a
          kitchen steps from your office. No soggy fries. No long wait times.
        </p>
        <p>
          Best Food Trucks (BFT) also provides an online ordering system for all
          our corporate lunch trucks so you never have to wait in line. When
          your food is ready, you’ll receive a text message so you can come grab
          it fresh. No lines means you can get more work done, relax more, or
          become your office hero.
        </p>
        <p>
          This is why we see a lot of offices starting to use corporate catering
          food trucks a lot more than traditional catering dishes. You get all
          the convenience of online ordering with the freshest possible food
          made right outside your doorstep.
        </p>
        <p>
          Typically, offices would have to manage this whole process themselves,
          negotiate minimums, check certifications and licenses, track booking,
          manage and track payouts, and routing customer feedback to the truck.
          Sounds fun, right?
        </p>
        <p>
          BFT does all of this, for FREE. We want to make sure you’re happy with
          your office food truck. So with our unique lunch ordering system for
          the offices along with our decade of relationships with food trucks in
          your area, you won’t have to dread the “What do you want to do for
          lunch?” question anymore.
        </p>
        <p>
          Let us know if you are interested in trying us out by clicking the
          button below. We’ll get back to you ASAP and try to help you become
          your office hero. :)
        </p>
        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  },
  {
    title: 'How does food truck booking at Best Food Trucks work?',
    categoryCode: 'catering',
    slug: 'how-does-best-food-trucks-work',
    html: (
      <div>
        <p>
          Best Food Trucks is an end-to-end solution to connect gourmet food
          trucks with customers in a variety of ways. We’ve built a unique
          software solution to streamline the process of food truck booking and
          scheduling trucks, and an exclusive order ahead technology that takes
          away the hassle of long lines and lets the food trucks focus on making
          food.
        </p>
        <p>
          There are many other food truck booking platforms, but not like BFT.
          For example, we have an exclusive order ahead technology so customers
          / tenants / attendees can see real photo menus, order from their phone
          or desktop, and get texted when their food is ready. In addition, we
          collect ratings via SMS so we have data when it comes to which food
          trucks you like, and which ones you don’t. That way, we can
          automatically optimize based on preferences.
        </p>
        <p>
          Plus, we truly pick from the best food trucks in your area. Our goal
          is to send a variety of trucks with quality food that’s healthy, and
          not just the same trucks every week.
        </p>
        <p>
          Most other companies simply send out food trucks and don’t bother to
          collect feedback from their customers. With other food companies you
          may waste your time waiting in line or waiting for your name to be
          called.
        </p>
        <p>
          We just want you to have the freshest food with a convenient ordering
          method from a food truck steps outside your office.
        </p>
        <p>So what does that mean? Here’s a few ways of how we work:</p>
        <h4>Office Catering</h4>
        <p>
          Our main business is helping offices and property managers in
          buildings with limited food options transform their lunch experience
          by booking a food truck business of your choosing. If you’re a
          property manager, you want to make sure your existing or prospective
          tenants are happy with their nearby food options otherwise they might
          choose somewhere else. And if you’re an employer, you want to keep
          employees happy with fresh food close to home.
        </p>
        <p>
          Typically, this means we’ll set up a regular daily spot Monday-Friday
          for local food trucks to come out. All we need is a loading bay, a
          saved parking spot, or somewhere that the food truck can park. If you
          know of a spot that’s not literally your building but across the
          street, we can work with that as well.
        </p>
        <p>
          We also do food truck catering for meetings as well. Whether you’re a
          salesperson looking to impress clients, an executive who wants to do
          something to reward their team, or just are tired of the same boring
          catering food (of which 25% goes straight to the trash bin), we can
          book the food truck for you and take care of the payment logistics.
          Plus, you can order online so your meeting goes smoothly without
          people having to wait in line.
        </p>
        <h4>Apartments</h4>
        <p>
          If you live in an apartment, email your leasing office about
          partnering with Best Food Trucks. We’ve helped numerous apartment
          buildings set up a regular stop for residents so they can enjoy fresh
          food literally steps from their front door.
        </p>
        <p>
          For apartments, we work with the leasing office to find the best spot
          (typically a parking spot, loading bay, or somewhere convenient for
          both the truck and residents) for the truck to park. Then, we’ll open
          up the spots to our amazing network of 1000+ food trucks we work with
          across the country and find the best local food trucks in your area.
        </p>
        <p>
          The process is incredibly easy to set up, and requires virtually no
          work on the apartment side. We totally get that leasing agents are
          incredibly busy as it is, so we do 99% of the work to help residents
          know about the trucks, order online, and answer any questions they
          might have via SMS catering.
        </p>
        <h4>Music Festivals / Events / Theme Parks</h4>
        <p>
          If you’re planning an event and want food trucks, please do yourself a
          favor and let us help you. With BFT’s advance food truck booking
          platform, attendees can order from their phone and get texted when
          their food is ready.
        </p>
        <p>
          Even if you’re experienced in booking food trucks in the past, without
          our order ahead technology, you’re basically going to waste an hour of
          your attendees’ time in line when they could be watching bands,
          walking around, spending more money, or enjoying the programming
          you’ve meticulously crafted.
        </p>
        <p>
          We can take care of booking food trucks for events along with the
          scheduling and negotiating, or plug-in to your existing process to
          help get it to the finish line along with day-of live monitoring to
          make sure everything goes smoothly.
        </p>
        <h4>Anything else?</h4>
        <p>
          We do a TON more with food trucks… everything from catering food
          trucks at weddings, charities, studio and on-set, to tv/film
          productions, concerts, stadiums, and more. If your project or
          application involves anything remotely involved with food trucks or
          you just want to upgrade your existing catering / food vendor
          experience, reach out and we’ll help you either way!
        </p>
        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  },
  {
    title: 'Which catering food trucks do you work with?',
    categoryCode: 'catering',
    slug: 'which-food-trucks-you-work-with',
    html: (
      <div>
        <p>
          Best Food Trucks (BFT) has partnered with food trucks across the city,
          including Los Angeles, Austin, Portland, D.C. and dozens more cities.
          So trust us when we say we have just the right food trucks to cater at
          all your events; corporate, weddings, or private celebrations.
        </p>

        <p>
          For example, in Los Angeles we’ve work with food trucks like Cousins
          Maine Lobster, The Grilled Cheese Truck, Baby’s Badass Burgers,
          Pickles & Peas Mediterranean, Maravilla Cuban-Colombian fusion,
          Roll&apos;n Lobster, Surfer Tacos, Dogtown Dogs, and 200+ more.
        </p>

        <p>
          However, many people believe that food trucks cater only unhealthy
          food, which dissuades them from visiting one to maintain a healthy
          diet.
        </p>

        <p>
          Putting this misconception to an end, BFT takes extra precautions to
          see that the food truck not only has a variety of cuisines, but makes
          sure that there are also healthy and “clean” food options. We’re not
          going to send five taco food trucks or the same local food trucks to
          your location in a row, and at the same time we’re not sending only
          salad trucks either. We work with food trucks to make sure they have a
          variety of options within their food truck menu, so each truck has
          something that’s healthy as well. Just because you’re eating from food
          trucks doesn’t mean you need to sacrifice your gluten-free, vegan or
          just healthy diet. We also offer halal food trucks.{' '}
        </p>

        <p>
          When you choose Best Food Trucks, we’ll work with you to know what
          kinds of trucks you like, or if you have requests for specific trucks
          that you love. Once we start booking food trucks, we’ll collect
          ratings from customers which will help us learn which trucks you love,
          and which ones are better suited for other spots.
        </p>
        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  },
  {
    title: 'Is there any work on my part?',
    categoryCode: 'catering',
    slug: 'is-there-any-work-on-my-part',
    html: (
      <div>
        <p>
          Best Food Trucks (BFT) works with over a thousand food trucks for
          catering across the country. Whether you’re looking for food trucks in
          Los Angeles, Austin, Portland, D.C., or dozens more cities across the
          country, BFT will find the right trucks for you.
        </p>

        <p>
          For example, in Los Angeles we’ve worked with food trucks like Cousins
          Maine Lobster, The Grilled Cheese Truck, Baby’s Badass Burgers,
          Pickles & Peas Mediterranean, Maravilla Cuban-Colombian fusion,
          Roll&apos;n Lobster, Surfer Tacos, Dogtown Dogs, and 200+ more.
        </p>

        <p>
          One common misconception that people sometimes have with food truck
          catering is that they are always unhealthy food. If you’re working in
          an office and you had colleagues head out to the food trucks, you
          might have been reluctant to go along if you’re trying to eat healthy,
          you’re watching calories, or you have dietary restrictions.
        </p>

        <p>
          When we put together the preference sheets for each location, we take
          into account not only a variety of cuisine, but making sure there are
          healthy food and “clean” food options. We’re not going to send five
          taco food trucks or cheesesteak trucks to your location in a row, and
          at the same time we’re not sending only salad trucks either. We work
          with food trucks to make sure they have a variety of options within
          their menu, so each truck has something that’s healthy as well. Just
          because you’re eating from food trucks doesn’t mean you need to
          sacrifice your gluten-free, vegan or just healthy diet.
        </p>

        <p>
          When you sign up for Best Food Trucks, we’ll work with you to create
          the preference sheet so you can let us know what kinds of trucks you
          like, or if you have requests for specific trucks that you love. Once
          we start booking food trucks, we’ll collect ratings from customers
          which will help us learn about your preference for hiring food trucks
          for weddings or corporate events.
        </p>
        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  },
  {
    title: 'How much do food trucks cost?',
    categoryCode: 'catering',
    slug: 'how-much-does-it-cost',
    html: (
      <div>
        <p>
          Signing up and integrating with Best Food Trucks (BFT) is FREE! If
          you’re a property manager, employer, commercial real estate company,
          apartment building, leasing office, or anyone else who wants to book
          food trucks on a regular stop or for catering / events, we don’t
          charge a dime.
        </p>

        <p>
          That means we’ll take care of all the logistics of booking food
          trucks, negotiating minimums, setting up a schedule of trucks that
          constantly shift cuisines, and providing an order ahead technology
          that allows people to order ahead from the food trucks via their
          desktop or phone web browser costs a grand total of zero!
        </p>

        <p>
          So how do we make our money? We work with the food trucks to provide
          them access & vending locations, and maximize business for them for a
          small convenience fee, approximately one third of our competitors. If
          we don’t send them wonderful customers like ourselves, we don’t make
          money ourselves. Either way, food truck catering costs you nothing.
        </p>

        <p>
          In short, if having a professional chef in a mobile kitchen parked
          right outside, which keeps your tenants, residents, and/or employees
          happy is completely free and makes you look great, what’s stopping you
          from signing up? :)
        </p>

        <p>
          Contact us for more information about the cost of food truck catering
          at events. Or, give us a call if you are interested in booking food
          trucks for your upcoming event.
        </p>

        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  },
  {
    title: 'How does order online work?',
    categoryCode: 'orderahead',
    slug: 'how-does-order-online-work',
    html: (
      <div>
        <p>
          Once you’ve signed up with Best Food Trucks (BFT), we’ll do all the
          work to setup online ordering for you. Here’s typically how it works:
        </p>
        <p>
          <ol>
            <li>
              First, we’ll give you a unique website URL like
              bestfoodtrucks.com/(yourname) to make it easy for you to bookmark
              so you always know where to go when ordering.
            </li>
            <li>
              As we book trucks, they will show up on your customized page. If
              it’s a regular stop, you can see the upcoming week along with the
              full schedule of trucks coming up. If it’s a one-time event,
              you’ll just see the available trucks.
            </li>
            <li>
              If there’s more than one truck, we’ll show you a couple of the
              most popular items each truck has first, then the full menu so
              it’s easier for you to choose which truck you’d like to eat from.
            </li>
            <li>
              When it’s the day of the food truck, we’ll email you the moment
              the truck goes live. Then, you can view the photos, choose what
              you want to order, and checkout. Typically we’ll ask for your
              email (so we can send you a receipt), mobile phone number (so we
              can send you order updates via text message), and a name (so the
              truck knows who you are on top of your order number).
            </li>
            <li>
              You’ll see the estimated time before you order so you have an idea
              as to how long it will take to be ready.
            </li>
            <li>
              We’ll take payment so you won’t have to pay at the truck. No more
              having to fumble for your card or wait for a credit card machine.
              If you want, we’ll keep your card on file so you can order quickly
              in the future. Once you get your account setup, it literally takes
              30 seconds for orders in the future, so it’s incredibly easy!
            </li>
            <li>
              That’s it! Sit back, relax, and we’ll text you when your food is
              ready. If for some reason the order takes longer than 15 minutes,
              we’ll give you regular text message updates so you don’t have to
              wonder how long your order will be ready.
            </li>
            <li>
              When you’re done, we’ll send a followup text asking if you want to
              rate your meal from 1 (worst) to 5 (best). This is totally
              optional, but it helps us learn what trucks you want more of, and
              which ones you don’t want back. Your happiness is incredibly
              important to us, so we want to make sure we’re sending the right
              trucks with the right food.
            </li>
          </ol>
        </p>
        <p>To sign up for Best Food Trucks (BFT), click the button below.</p>
        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  },
  {
    title: 'What does a food truck need to receive online orders?',
    categoryCode: 'orderahead',
    slug: 'what-food-truck-needs-for-online-orders',
    html: (
      <div>
        <p>
          We’ve tried here at Best Food Trucks (BFT) to keep the process as
          simple as possible, and with the least amount of requirements so
          online ahead / online order works for everyone.
        </p>
        <p>
          <strong>
            All you’ll need is an iOS or Android device, which can be your
            existing point-of-sale or a separate tablet or phone, and a mobile
            phone where you can send and receive text messages.{' '}
          </strong>
        </p>
        <p>
          You’ll need the iOS / Android tablet or phone so you can download our
          app for food trucks, where incoming order ahead / online orders will
          come. It’s just a matter of preference for you if you want to use the
          same device as your point-of-sale, or keep another phone on the food
          truck purely for online orders.
        </p>
        <p>
          When new orders come in, you’ll receive a text message in addition to
          an in-app notification with a loud “DING”, so just make sure you have
          something where your volume is turned up. If you have a credit card
          reader that’s plugged into your headphone jack, we’ll also send you a
          text message to whatever phone number you associate with the shift as
          a backup.
        </p>
        <p>
          Lastly, you need a mobile phone so we can send you text messages. For
          example, if you have a question mid-shift, you can text us and we’ll
          respond ASAP. When customers rate your food, you’ll receive a text
          message instantly with their response and any feedback for the dish so
          you can even switch things up mid-shift. Didn’t realize that you were
          putting on too much tzatziki sauce? You’ll get a notification via text
          for any customer feedback.
        </p>
        <p>
          You don’t need any special equipment, and since the app is
          self-sufficient and doesn’t need to be integrated directly into the
          point-of-sale, it will work with whatever current setup you have.
        </p>
        <p>
          If your setup requires something different, please reach out to us at{' '}
          <a href="mailto:support@bestfoodtrucks.com">
            support@bestfoodtrucks.com
          </a>{' '}
          and we’ll work with you to get you up and running.
        </p>
      </div>
    )
  },
  {
    title: 'What if we already have food trucks?',
    categoryCode: 'catering',
    slug: 'what-if-we-already-have-food-trucks',
    html: (
      <div>
        <p>
          If you already have food trucks for your daily workplace catering,
          that means you also already have lines during lunch, no way for people
          to order and see photo menus, no schedule, and no ratings feedback
          collected by customers so there’s no iteration based upon what people
          actually like.
        </p>
        <p>
          When office buildings switch over to us, we typically see the same
          setup in most cases. Either there’s no dedicated page that shows an
          upcoming schedule, or an email has to be sent out daily, or there’s a
          google calendar that has names of trucks but no menus, or basic flyers
          for food trucks that might have a menu that is months old and severely
          outdated (as food trucks change their menus frequently).
        </p>
        <p>
          We receive ratings every day from every lot that we’re active on which
          we use to then iterate the selection to ensure everyone is happy.
          Traditional food truck companies have virtually zero relationship with
          your people, so they have no idea what people like or don’t like, and
          thus they’re stuck sending the same trucks over and over.
        </p>
        <p>
          In addition, we have a dedicated support team working around the clock
          via text message so when people have questions or need help with their
          order, we respond.
        </p>
        <p>
          But most importantly, why would anyone want to wait in line at the
          truck when they could order ahead, and pick up their food when it’s
          ready? The biggest reason why office buildings switch to Best Food
          Trucks (BFT) is simply because they want their employees or tenants to
          enjoy their lunch hour and not waste it in line. When customers order
          online from our available food truck menu, they can get more work
          done, or simply relax and enjoy their lunch hour talking to friends
          instead of literally standing in place for 20-30 minutes.
        </p>
        <p>
          In other words, the difference is night and day. If you have an office
          lunch catering via food trucks already, then let us help make your
          employees&apos; lives easier and sign up today. We’ll keep any food
          trucks you already have scheduled and turn on an online lunch ordering
          system for the offices so people can start enjoying the benefits
          immediately. We’ll also handle all the logistics of switching food
          truck vendors so you don’t have to lift a finger.
        </p>
        <p>
          Plus, when you start getting emails from happy people thanking you for
          enabling order ahead and allowing them to take back their lunch hour,
          you’ll wonder to yourself why you ever had people wait in line in the
          first place.
        </p>

        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  },
  {
    title:
      'Why should I switch from my current catering service to food truck catering?',
    categoryCode: 'catering',
    slug: 'what-if-my-office-or-business-already-has-catering',
    hideForBoeing: true,
    html: (
      <div>
        <p>
          If you already get food from banquet or trough-style catering in giant
          metal trays, then many corporate food truck catering problems might
          look familiar to you.
        </p>
        <p>
          The problem with traditional catering is that you’re making food for
          everyone, but not everyone is going to eat it. And the rest of that
          food becomes waste that goes straight into the garbage. The best part?
          You’re paying for all that food that gets dumped.
        </p>

        <p>
          Catering dishes provide very little choice for people, especially for
          those with dietary restrictions. Typically you’re going to have 1-2
          meat plates, 1 veggie option, maybe 1-2 sides, and that’s it. Forget
          about the flexibility of a full menu, or items like burritos or
          sandwiches with custom fillings. And if someone is allergic to garlic
          or something specific, they might literally not be able to eat
          anything with this cookie-cutter approach.
        </p>
        <p>
          While catering trays might be kept hot, they certainly aren’t fresh or
          made-to-order. Those metal trays had food in them likely hours before
          you even eat it, so the chances of getting fresh, crunchy, or
          non-soggy food becomes slim to none.
        </p>
        <p>
          In other words, if you’re using traditional catering, you’re paying
          for food that gets thrown into the garbage, you’re limiting your
          options, you’re not providing options for those with dietary
          restrictions or food allergies, you’re not allowing people to make
          their own choices about what they want to eat, and you’re having
          people settle for food premade hours before they actually eat it.
        </p>
        <p>
          With Best Food Trucks (BFT), food is only made for people ordering, so
          there’s drastically less food waste. Instead of only one or two main
          options, people can choose from a full food truck menu of delicious
          entrees and appetizers. We have everything from coffee, dessert, ice
          cream food trucks to hotdog and tacos food trucks. If someone hates
          mushrooms or is allergic to garlic or is vegan / gluten-free or wants
          the sauce on the side or any other modification, they have the choice
          to eat what they actually want. We even have halal food trucks for
          hire.
        </p>
        <p>
          To help companies with catering switch to this drastically better
          experience with corporate food truck catering, Best Food Trucks (BFT)
          also offers a streamlined employer-paid lunch program, meaning offices
          can put a payment option on file, select which days and how much
          they’re willing to cover, and create a whitelist of people who are
          eligible.
        </p>
        <p>
          For example, an office can set eligible days to every Monday /
          Wednesday / Friday, and up to $15 in credits for employees. Anything
          over $15, the employee can put a card on file and cover. This program
          applies to all the food truck rentals on our platform, so no need to
          keep running down to food trucks with a corporate card for every lunch
          shift.
        </p>
        <p>
          Now, companies are only paying for food that’s actually used, rather
          than ordering catering for everyone and having people who called in
          sick, didn’t like the option, were too busy in a meeting, or any other
          reason to not eat and have that food thrown out.
        </p>
        <p>
          Plus, we’ll give companies reports on which food trucks showed up, how
          many orders, reviews and feedback, and other reporting to provide
          transparency in the cost of food trucks and how the program is going
          overall.
        </p>
        <p>
          If you’re interested in upgrading your catering experience and making
          your employees’ happier and more productive, click the button below
          and we’ll set you up with a regular food truck schedule along with
          setting up your employer-paid lunch program. Give us a call to book or
          learn more about our food truck prices and other related information.
        </p>
        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  },
  {
    title: 'Do you help with food truck office catering?',
    categoryCode: 'catering',
    slug: 'do-you-help-with-food-truck-catering',
    hideForBoeing: true,
    html: (
      <div>
        <p>
          Food truck office catering is a great option for any event, especially
          when the food will be served hot and ready. One of the best features
          about catering food for office lunch via food truck is that people can
          order made-to-order meals in hot and ready forms instead of having to
          scoop out pre-made servings from chafing dishes. In this blog post, we
          are going to talk about 5 reasons why you should consider using food
          trucks for your next catered event!
        </p>
        <p>How does food truck catering work?</p>
        <ol>
          <li>
            Made-to-order Meals: Food trucks offer made to order meals rather
            than scooping out pre-made servings from chafing dishes.
          </li>
          <li>
            Food Trucks can accommodate special requests like allergies, gluten
            intolerance and vegetarian.
          </li>
          <li>
            Food truck catering is fun! Bringing a food truck to your office,
            home or school always pleases a crowd.
          </li>
          <li>
            When you work with food trucks for a healthy office lunch catering
            you know you&apos;re supporting a small local business who directly
            benefits the local community.
          </li>
          <li>
            Food truck corporate catering is awesome! Awesome innovative
            cuisines from all corners of the planet can be found on food trucks
            nationwide. Food truck catering is the right choice for feeding
            friends, family and co-workers.
          </li>
        </ol>
        <ButtonLink
          color="primary"
          href="https://www.bestfoodtrucks.com/hire-food-truck"
          variant="contained"
        >
          Yes, I want Food Trucks for my office!
        </ButtonLink>
      </div>
    )
  }
]

export default faq

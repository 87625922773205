import React from 'react'
import Image from 'components/Image'
import ButtonLink from 'components/ui/ButtonLink'
import { useRouter } from 'next/router'
import faq from 'utils/faq'
import Link from 'next/link'
import { logEvent } from 'utils/analytics'

import logoUrl from '../../images/bft-logo-black-truck-only-small.png'

import instagram from './instagram/instagram.png'

const FooterColumn = ({ children }: { children: React.ReactElement }) => (
  <div className="w-full md:w-1/2 lg:w-1/3 pt-10 pb-8 px-4">{children}</div>
)

const FooterColumnTitle = ({ children }: { children: string }) => (
  <h5 className="uppercase font-semibold tracking-wider text-xs pb-5 mb-6 border-b border-solid border-1 border-gray-700">
    {children}
  </h5>
)

const FooterLink = ({
  href,
  children,
  legacy = false
}: {
  href: string
  children: string
  legacy?: boolean
}) => {
  const className =
    'text-white hover:text-text-white transition-all duration-200'
  if (
    legacy ||
    href.includes('http://') ||
    href.includes('https://') ||
    href.includes('mailto:') ||
    href.includes('tel:')
  ) {
    const isPhone = href.includes('tel:')
    return (
      <a
        className={className}
        href={href}
        // eslint-disable-next-line consistent-return
        onClick={() => {
          if (isPhone) {
            // support_phone_click_ads
            logEvent('conversion', {
              send_to: 'AW-768772864/aN4iCJ_xn4kZEICWyu4C',
              event_callback: () => {
                // @ts-ignore
                window.location = href
              }
            })
            return false
          }
        }}
      >
        {children}
      </a>
    )
  }
  return (
    <Link className={className} href={href} prefetch={false}>
      {children}
    </Link>
  )
}

const Faq = ({ children }: { children: React.ReactElement }) => (
  <div className="mb-3 pb-3 border-b border-solid border-1 border-gray-700">
    {children}
  </div>
)

const Footer = ({ hideFooter = false }: { hideFooter?: boolean }) => {
  const router = useRouter()
  if (hideFooter) return null
  const isBoeing = router.asPath.includes('boeing')
  return (
    <footer className="bg-background-dark text-white">
      <div className="max-w-7xl px-4 mx-auto text-sm leading-7 box-border">
        <div className="flex flex-wrap box-border">
          <FooterColumn>
            <div className="text-text-white">
              <Image
                alt="BFT logo"
                className="mb-5"
                height={56}
                src={logoUrl}
                width={100}
              />
              <p className="mb-5">
                Best Food Trucks (BFT) is the nation&apos;s largest food truck
                booking & ordering platform. From location management & food
                truck catering to our exclusive order ahead technology to
                setting up food trucks at your office or event, Best Food Trucks
                will handle all the logistics so you can focus on the food.
              </p>
              <div className="mb-4">
                <ButtonLink
                  color="secondary"
                  href="/food-trucks-map"
                  prefetch={false}
                  variant="transparent"
                  fullWidth
                >
                  Food trucks map
                </ButtonLink>
              </div>
              <div className="mb-4">
                <ButtonLink
                  color="secondary"
                  href="/experiential-marketing"
                  prefetch={false}
                  variant="transparent"
                  fullWidth
                >
                  Experiential marketing with BFT
                </ButtonLink>
              </div>
              <div className="mb-4">
                <ButtonLink
                  color="secondary"
                  href="/municipal"
                  prefetch={false}
                  variant="transparent"
                  fullWidth
                >
                  Municipal
                </ButtonLink>
              </div>
              <p>
                E-mail:{' '}
                <FooterLink href="mailto:support@bestfoodtrucks.com">
                  support@bestfoodtrucks.com
                </FooterLink>
              </p>
              <p>
                Text BFT Support:{' '}
                <FooterLink href="tel:+18333780040">(833) 378-0040</FooterLink>
              </p>
              <p>
                <FooterLink href="/blog">Blog</FooterLink>
              </p>
              <p>
                <FooterLink href="/terms">Terms of service</FooterLink>
              </p>
              <p>
                <FooterLink href="/privacy" legacy>
                  Privacy
                </FooterLink>
              </p>
              <p>
                <FooterLink href="/dmca">
                  Copyright Policy / DMCA Agent
                </FooterLink>
              </p>
            </div>
          </FooterColumn>
          <FooterColumn>
            <>
              <FooterColumnTitle>Office Catering</FooterColumnTitle>
              {faq
                .filter((q) => (isBoeing ? !q.hideForBoeing : true))
                .map((q) => (
                  <Faq key={q.slug}>
                    <FooterLink href={`/faq/${q.categoryCode}/${q.slug}`}>
                      {q.title}
                    </FooterLink>
                  </Faq>
                ))}
            </>
          </FooterColumn>
          <FooterColumn>
            <div>
              <FooterColumnTitle>Instagram</FooterColumnTitle>
              <a
                href="https://www.instagram.com/bestfoodtrucksusa/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  alt="instagram-image"
                  height={320}
                  layout="responsive"
                  src={instagram}
                  width={320}
                />
              </a>
            </div>
          </FooterColumn>
        </div>
        <div className="border-t border-solid border-1 border-gray-700 py-6 mx-auto uppercase text-xs text-text-white font-semibold tracking-wider text-center">
          <p>
            © {new Date().getFullYear()} Best Food Trucks Inc., All Rights
            Reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
